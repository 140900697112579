<template>
	<div class="wrapper statistics">
		<div class="statistics-header">
			<div class="left">
				<div class="main-nav">
					<div class="nav-item" :class="activeIndex==index?'active':''" v-for="(item,index) in navList" :key="item.id"
						@click="navItem(index)">{{ item.name }}
					</div>
				</div>
				<el-date-picker @change="monthChange" class="picker-month" value-format="yyyy-MM" v-model="monthValue"
					type="month" placeholder="选择月" align="center"></el-date-picker>
			</div>
		</div>
		<el-row :gutter="15" style="margin-top:10px;margin-bottom:10px;">
			<el-col :md="24" :lg="6">
				<freightProp :type="activeIndex" :month="monthValue" @followUpClick="followUpClick" ref="freightProp"></freightProp>
			</el-col>
			<el-col :md="24" :lg="6">
				<goodsAmount :type="activeIndex" :month="monthValue" @followUpClick="followUpClick" ref="goodsAmount"></goodsAmount>
			</el-col>
			<el-col :md="24" :lg="6">
				<freightAmount :type="activeIndex" :month="monthValue" @followUpClick="followUpClick" ref="freightAmount"></freightAmount>
			</el-col>
			<el-col :md="24" :lg="6">
				<enteredBy :type="activeIndex" :month="monthValue" ref="enteredBy"></enteredBy>
			</el-col>
		</el-row>
		<el-row :gutter="15">
			<el-col :md="24" :lg="24">
				<followedUpTable ref="followedUpTable" @revokeFollowUpClick="revokeFollowUpClick"></followedUpTable>
			</el-col>
		</el-row>

	</div>
</template>

<script>
	import freightProp from './components/freightProp.vue'
	import goodsAmount from './components/goodsAmount.vue'
	import freightAmount from './components/freightAmount.vue'
	import enteredBy from './components/enteredBy.vue'
	import followedUpTable from './components/followedUpTable.vue'
	export default {
		components: {
			freightAmount,
			goodsAmount,
			freightProp,
			enteredBy,
			followedUpTable
		},
		data() {
			return {
				navList: [{
					id: 0,
					name: "全部"
				}, {
					id: 1,
					name: "手工"
				}],
				activeIndex: 0,
				monthValue: new Date(),
			}
		},
		methods: {
			// 回访
			followUpClick() {
				this.$refs.followedUpTable.refreshData()
			},
      revokeFollowUpClick() {
				this.$refs.freightProp.refreshData()
				this.$refs.goodsAmount.refreshData()
				this.$refs.freightAmount.refreshData()
			},
			monthChange(e) {
				this.monthValue = e
			},
			navItem(index) {
				this.activeIndex = index
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "~@/assets/css/statistics.scss";
	.picker-month{
		margin: 0 0 0 10px;
	}
</style>
