<template>
  <div class="statistics-card">
    <div class="statistics-card-title">
      <span style="float: left; font-weight: bolder">录入人统计情况</span>
      <el-button size="mini" @click="exportExcel" type="primary">导出</el-button>
    </div>
    <el-table :data="tableData" class="statistics-table" height="320" :loading="loading">
<!--      <el-table-column prop="type" label="类型">-->
<!--        <template slot-scope="scope">-->
<!--          <div class="title-index">-->
<!--            <i class="icon"-->
<!--               :class="{'orange':scope.$index%5==1,'yellow':scope.$index%5==2,'green':scope.$index%5==3,'red':scope.$index%5==4}">{{-->
<!--                scope.$index + 1-->
<!--              }}</i>-->
<!--            <span>{{ scope.row.sparePartsTypeName }}</span>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="name" label="姓名" align="center" width="75"></el-table-column>
      <el-table-column prop="regionName" label="地区" align="center" width="60"></el-table-column>
      <el-table-column prop="proportion" label="通过率" width="60" align="center">
        <template slot-scope="scope">
          <span class="red">{{ scope.row.proportion }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="passNum" label="通过数" align="center" width="80"></el-table-column>
      <el-table-column prop="notPassNum" label="失败数" align="center" width="60"></el-table-column>
      <el-table-column prop="pickupNum" label="用户自提" align="center" width="70"></el-table-column>
      <el-table-column prop="total" label="总录入数" align="center" width="80"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import {assessment} from "@/api/statistic";
import {getDate} from "@/common/common";

export default {
  props: ['month', 'type'],
  watch: {
    type() {
      this.getData()
    },
    month() {
      this.query.month = this.month
      this.getData()
    }
  },
  data() {
    return {
      tableData: [],
      query: {
        month: getDate()
      },
      loading: false,
      activeRow: null,
      downloadLoading: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    refreshData() {
      this.getData()
    },
    getData() {
      this.loading = true
      assessment({type: this.type, month: this.query.month}).then(res => {
        if (res.code === 0) {
          this.tableData = res.data
          this.loading = false
        } else {
          this.$message.error(res.desc)
          this.loading = false
        }
      })
    },
    exportExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出Excel表格，请耐心等待！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.downloadLoading = true
      let tHeader = [
        "姓名",
        "地区",
        "通过率",
        "通过数",
        "失败数",
        "用户自提",
        "总录入数"
      ]
      import('@/vendor/Export2Excel').then(excel => {
        let list = this.tableData
        const data = this.formatJson([
          "name",
          "regionName",
          "proportion",
          "passNum",
          "notPassNum",
          "pickupNum",
          "total"], list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '人员录入情况表',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
        loading.close();
        this.dialogExportVisible = false
      })

    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {

        if (j === "proportion") {
          return v[j] + '%'
        }
        return v[j]
      }))
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/statistics.scss";

.red {
  color: red;
}
</style>
