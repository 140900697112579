var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistics-card pass-table"},[_c('div',{staticClass:"table-wrapper"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"filterTable",staticClass:"main-table",attrs:{"data":_vm.tableData,"border":"","header-row-class-name":"table-header","highlight-current-row":"","height":"330"}},[_c('el-table-column',{attrs:{"type":"selection","width":"40"}}),_c('el-table-column',{attrs:{"label":"序号","type":"index","align":"center","width":"60","fixed":""}}),_c('el-table-column',{attrs:{"label":"录入日期","prop":"entryDate","align":"center","width":"110","fixed":""}}),_c('el-table-column',{attrs:{"label":"提货单编号","prop":"orderNum","align":"center","width":"150","fixed":""}}),_c('el-table-column',{attrs:{"label":"类型","prop":"sparePartsTypeName","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"单位简称","prop":"unitName","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"提货金额（元）","prop":"totalAmount","align":"center","width":"150"}}),_c('el-table-column',{attrs:{"label":"提货件数","prop":"totalNum","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.totalNum)+" "),_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.pickingDetails(row)}}},[_vm._v("提货明细")])]}}])}),_c('el-table-column',{attrs:{"label":"状态","prop":"status","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status== -1)?_c('el-tag',[_vm._v("未提交")]):_vm._e(),(row.status==1)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("待审核")]):_vm._e(),(row.status==2)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已通过")]):_vm._e(),(row.status==3)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("未通过")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"不通过原因","prop":"notPassReason","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"运单号","prop":"trackingNumber","align":"center","width":"140"}}),_c('el-table-column',{attrs:{"label":"计费方式","prop":"billingMethod","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.billingMethod==1)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("重量")]):_vm._e(),(row.billingMethod==2)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("体积")]):_vm._e(),(row.billingMethod==3)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("发货件数")]):_vm._e(),(row.billingMethod==4)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("重抛")]):_vm._e(),(row.billingMethod==5)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("整车")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"发货件数","prop":"num","align":"center","width":"140"}}),_c('el-table-column',{attrs:{"label":"重量（kg）","prop":"weight","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"体积（m³）","prop":"volume","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"运费（元）","prop":"freight","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"备注","prop":"remark","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"备注2","prop":"remark2","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"收货人","prop":"receiver","align":"center"}}),_c('el-table-column',{attrs:{"label":"收货人电话","prop":"receiverPhone","align":"center","width":"140"}}),_c('el-table-column',{attrs:{"label":"发出时间","prop":"deliveryDate","align":"center","width":"110"}}),_c('el-table-column',{attrs:{"label":"收货地","prop":"address","align":"center","width":"150"}}),_c('el-table-column',{attrs:{"label":"物流公司","prop":"logisticsCompanyName","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"prop":"isFree","label":"免运费","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isFree==1)?_c('el-tag',[_vm._v("是")]):_vm._e(),(row.isFree==2)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("否")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"发货员","prop":"consignorName","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"送货上门","prop":"isVisit","align":"center","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isVisit==1)?_c('el-tag',[_vm._v("是")]):_vm._e(),(row.isVisit==2)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("否")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"物流单照片","prop":"imgUrl","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"23px","height":"23px"},attrs:{"src":row.imgUrl,"preview-src-list":row.imgList.map(function (e) { return e.url; })}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"300","align":"center","class-name":"table-operate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.common.followUp())?_c('el-button',{attrs:{"type":"warning","size":"mini","round":""},on:{"click":function($event){return _vm.revoke(row)}}},[_vm._v("撤回 ")]):_vm._e()]}}])})],1)],1),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next,total,jumper","total":_vm.total,"current-page":_vm.query.page,"page-size":_vm.query.pagesize},on:{"update:currentPage":function($event){return _vm.$set(_vm.query, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.query, "page", $event)},"current-change":_vm.getList}}),_c('el-dialog',{attrs:{"title":"提货通知单明细","visible":_vm.dialogTableVisible},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('div',{staticClass:"picking-header"},[_c('div',{staticClass:"picking-title"},[_vm._v("提货通知单号："+_vm._s(_vm.pickingData.orderNum))]),_c('div',{staticClass:"picking-title"},[_vm._v("总价："+_vm._s(_vm.pickingData.totalAmount)+"元")])]),_c('el-table',{staticClass:"main-table",attrs:{"data":_vm.pickingData.invoiceDetails,"border":"","header-row-class-name":"table-header","highlight-current-row":"","height":"500"}},[_c('el-table-column',{attrs:{"property":"sparePartCode","label":"备件代码"}}),_c('el-table-column',{attrs:{"property":"sparePartName","label":"备件名称"}}),_c('el-table-column',{attrs:{"property":"num","label":"数量"}}),_c('el-table-column',{attrs:{"property":"costPrice","label":"单价"}}),_c('el-table-column',{attrs:{"property":"amount","label":"金额"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }