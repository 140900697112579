<template>
	<div class="">
		<el-dialog title="订单详情" :visible.sync="dialogVisible" width="80%">
			<div class="statistics-card pass-table">
        <el-button type="primary" @click="exportExcel" style="margin-bottom: 10px">导出Excel表格
        </el-button>
				<div class="table-wrapper">
					<el-table class="main-table" :data="tableData" border header-row-class-name="table-header"
						highlight-current-row ref="filterTable" v-loading="loading" height="330">
						<el-table-column type="selection" width="40"></el-table-column>
						<el-table-column label="序号" type="index" align="center" width="60" fixed></el-table-column>
						<el-table-column label="录入日期" prop="entryDate" align="center" width="110" fixed>
						</el-table-column>
            <el-table-column label="地区" prop="region" align="center"></el-table-column>
            <el-table-column label="提货单编号" prop="orderNum" align="center" width="150" fixed></el-table-column>
						<el-table-column label="类型" prop="sparePartsTypeName" align="center" width="120"></el-table-column>
						<el-table-column label="单位简称" prop="unitName" align="center" width="120"></el-table-column>
						<el-table-column label="提货金额（元）" prop="totalAmount" align="center" width="150"></el-table-column>
						<el-table-column label="提货件数" prop="totalNum" align="center" width="150">
							<template slot-scope="{row}">
								{{ row.totalNum }}
								<el-button type="primary" size="mini" round @click="pickingDetails(row)">提货明细</el-button>
							</template>
						</el-table-column>
						<el-table-column label="状态" prop="status" align="center" width="120">
							<template slot-scope="{row}">
								<el-tag v-if="row.status== -1">未提交</el-tag>
								<el-tag type="info" v-if="row.status==1">待审核</el-tag>
								<el-tag type="success" v-if="row.status==2">已通过</el-tag>
								<el-tag type="danger" v-if="row.status==3">未通过</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="不通过原因" prop="notPassReason" align="center" width="120"></el-table-column>
						<el-table-column label="运单号" prop="trackingNumber" align="center" width="140"></el-table-column>
						<el-table-column label="计费方式" prop="billingMethod" align="center" width="120">
							<template slot-scope="{row}">
								<el-tag type="info" v-if="row.billingMethod==1">重量</el-tag>
								<el-tag type="info" v-if="row.billingMethod==2">体积</el-tag>
								<el-tag type="info" v-if="row.billingMethod==3">发货件数</el-tag>
								<el-tag type="info" v-if="row.billingMethod==4">重抛</el-tag>
								<el-tag type="info" v-if="row.billingMethod==5">整车</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="发货件数" prop="num" align="center" width="140"></el-table-column>
						<el-table-column label="重量（kg）" prop="weight" align="center" width="120"></el-table-column>
						<el-table-column label="体积（m³）" prop="volume" align="center" width="120"></el-table-column>
						<el-table-column label="运费（元）" prop="freight" align="center" width="120"></el-table-column>
						<el-table-column label="备注" prop="remark" align="center" width="120"></el-table-column>
						<el-table-column label="备注2" prop="remark2" align="center" width="120"></el-table-column>
						<el-table-column label="收货人" prop="receiver" align="center"></el-table-column>
						<el-table-column label="收货人电话" prop="receiverPhone" align="center" width="140"></el-table-column>
						<el-table-column label="发出时间" prop="deliveryDate" align="center" width="110"></el-table-column>
						<el-table-column label="收货地" prop="address" align="center" width="150"></el-table-column>
						<el-table-column label="物流公司" prop="logisticsCompanyName" align="center" width="120">
						</el-table-column>
						<el-table-column prop="isFree" label="免运费" width="120" align="center">
							<template slot-scope="{row}">
								<el-tag v-if="row.isFree==1">是</el-tag>
								<el-tag type="danger" v-if="row.isFree==2">否</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="发货员" prop="consignorName" align="center" width="120"></el-table-column>
						<el-table-column label="送货上门" prop="isVisit" align="center" width="140">
							<template slot-scope="{row}">
								<el-tag v-if="row.isVisit==1">是</el-tag>
								<el-tag type="danger" v-if="row.isVisit==2">否</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="物流单照片" prop="imgUrl" align="center" width="120">
							<template slot-scope="{row}">
								<el-image style="width:23px; height:23px" :src="row.imgUrl"
									:preview-src-list="row.imgList.map(e => e.url)">
								</el-image>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<!-- <el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList"></el-pagination> -->

			</div>
		</el-dialog>

		<el-dialog title="提货通知单明细" :visible.sync="dialogTableVisible">
			<div class="picking-header">
				<div class="picking-title">提货通知单号：{{ pickingData.orderNum }}</div>
				<div class="picking-title">总价：{{ pickingData.totalAmount }}元</div>
			</div>
			<el-table :data="pickingData.invoiceDetails" class="main-table" border header-row-class-name="table-header"
				highlight-current-row height="500">
				<el-table-column property="sparePartCode" label="备件代码"></el-table-column>
				<el-table-column property="sparePartName" label="备件名称"></el-table-column>
				<el-table-column property="num" label="数量"></el-table-column>
				<el-table-column property="costPrice" label="单价"></el-table-column>
				<el-table-column property="amount" label="金额"></el-table-column>
			</el-table>
		</el-dialog>
	</div>

</template>

<script>
import {
  listByInvoiceId,
  listByLogisticsInfo,
  selectGroup
} from "@/api/logisticsInformation"

	export default {
		props: ['row', 'invoiceId', 'type'],
		data() {
			return {
				loading: false,
				resetShow: false,
				dialogVisible: false,
				screenTime: '',
				pickingData: "",
				dialogTableVisible: false,
				tableData: [],
				total: 10,
				query: {
          type: 0,
					addressId: null,
					logisticsCompanyId: null,
					trackingNumber: null,
				},
				isVisitArr: [],
				isFreeArr: [],
				companyArr: [],
				consignorArr: [],
        downloadLoading: false


			}
		},
		mounted() {
			this.resetShow = true
		},
		watch: {

			row() {
				this.query.addressId = this.row.addressId
				this.query.logisticsCompanyId = this.row.logisticsCompanyId
				this.query.trackingNumber = this.row.trackingNum
        this.query.type = this.type
				this.getListByLogisticsInfo()
			},
			invoiceId() {
				this.getListByInvoiceId()
			},

		},
		methods: {

			show() {
				this.dialogVisible = true
			},
			getGroup() {
				selectGroup(this.query.status, 'is_visit').then(res => {
					this.isVisitArr = res.data
					this.query.isVisitArr = this.isVisitArr.map(e => e.value)
				})
				selectGroup(this.query.status, 'consignor_id').then(res => {
					this.consignorArr = res.data
					this.query.consignorArr = this.consignorArr.map(e => e.value)
				})
				selectGroup(this.query.status, 'logistics_company_id').then(res => {
					this.companyArr = res.data
					this.query.companyArr = this.companyArr.map(e => e.value)

				})
				selectGroup(this.query.status, 'is_free').then(res => {
					this.isFreeArr = res.data
					this.query.isFreeArr = this.isFreeArr.map(e => e.value)
				})
			},
      exportExcel(){
        const loading = this.$loading({
          lock: true,
          text: '正在导出Excel表格，请耐心等待！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });



        this.downloadLoading = true
        let tHeader =[
          "备品类型",
          "提货单编号",
          "单位简称",
          "提货金额",
          "收货人地址",
          "收货人姓名",
          "收货人电话",
          "发货件数",
          "发货物流",
          "运单号",
          "物流电话",
          "发出时间",
          "运费",
          "是否免费",
          "发货人",
          "重量",
          "体积",
          "计费方式",
          "备注",
          "备注2",
          "是否送货上门",
          "状态",
          "录入日期"
        ]


          import('@/vendor/Export2Excel').then(excel => {
            let list = this.tableData
            const data = this.formatJson([
              "sparePartsTypeName",
              "orderNum",
              "unitName",
              "totalAmount",
              "address",
              "receiver",
              "receiverPhone",
              "num",
              "logisticsCompanyName",
              "trackingNumber",
              "logisticsCompanyPhone",
              "deliveryDate",
              "freight",
              "isFree",
              "consignorName",
              "weight",
              "volume",
              "billingMethod",
              "remark",
              "remark2",
              "isVisit",
              "status",
              "entryDate"
            ], list)
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: '物流明细单',
              autoWidth: true,
              bookType: 'xlsx'
            })
            this.downloadLoading = false
            loading.close();
            this.dialogExportVisible = false
          })


      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {

          if (j === "isVisit") {
            return v[j] === 1 ? "是" : "否"
          }
          if (j === "isFree") {
            return v[j] === 1 ? "是" : "否"
          }

          if (j === 'status') {
            let text = ''
            switch (v[j]) {
              case -1:
                text = '未提交'
                break
              case 1:
                text = '待审核'
                break
              case 2:
                text = '已通过'
                break
              case 3:
                text = '未通过'
                break
            }
            return text
          }

          if (j === 'billingMethod') {
            let text = ''
            switch (v[j]) {
              case 1:
                text = '重量'
                break
              case 2:
                text = '体积'
                break
              case 3:
                text = '发货件数'
                break
              case 4:
                text = '重抛'
                break
              case 5:
                text = '整车'
                break
            }
            return text
          }

          return v[j]
        }))
      },
			screenTimeChange() {
				this.query.entryDate = this.screenTime
				this.getList()
			},
			pickingDetails(row) {
				this.dialogTableVisible = true
				this.pickingData = row
			},
			getListByLogisticsInfo() {
				this.loading = true
				listByLogisticsInfo(this.query).then(res => {
					this.tableData = res.data
					this.loading = false
				})
			},

			getListByInvoiceId() {
				this.loading = true
				listByInvoiceId({
					invoiceId: this.invoiceId,
          type: this.type
				}).then(res => {
					this.tableData = res.data
					this.loading = false
				})
			},


			filterConsignor(value, row, column) {
				this.query.consignorArr = column.filteredValue
				this.consignorArrWatch = column.filteredValue
				return row.consignorId === value;
			},
			filterCompany(value, row, column) {
				this.query.companyArr = column.filteredValue
				this.companyArrWatch = column.filteredValue
				return row.logisticsCompanyId === value;

			},
			filterDeliver(value, row, column) {
				this.query.isVisitArr = column.filteredValue
				this.isVisitArrWatch = column.filteredValue
				return row.isVisit === value;
			},
			filterFreight(value, row, column) {
				this.query.isFreeArr = column.filteredValue
				this.isFreeArrWatch = column.filteredValue
				return row.isFree === value;
			},
		}
	}
</script>
<style lang="scss">
	.el-table-filter {
		.el-table-filter__bottom {
			button {
				width: 100%;
				border: 1px solid #C0C4CC;
				border-radius: 3px;

				&:last-child {
					display: none !important;
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
	// 提货明细
	.picking-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.picking-title {
			font-size: 18px;
			margin: 0 0 10px 0;
		}
	}
</style>
