<template>
  <div class="statistics-card pass-table">
    <div class="table-wrapper">
      <el-table class="main-table" :data="tableData" border header-row-class-name="table-header"
                highlight-current-row ref="filterTable" v-loading="loading" height="330">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="序号" type="index" align="center" width="60" fixed></el-table-column>
        <el-table-column label="录入日期" prop="entryDate" align="center" width="110" fixed>
        </el-table-column>
        <el-table-column label="提货单编号" prop="orderNum" align="center" width="150" fixed></el-table-column>
        <el-table-column label="类型" prop="sparePartsTypeName" align="center" width="120"></el-table-column>
        <el-table-column label="单位简称" prop="unitName" align="center" width="120"></el-table-column>
        <el-table-column label="提货金额（元）" prop="totalAmount" align="center" width="150"></el-table-column>
        <el-table-column label="提货件数" prop="totalNum" align="center" width="150">
          <template slot-scope="{row}">
            {{ row.totalNum }}
            <el-button type="primary" size="mini" round @click="pickingDetails(row)">提货明细</el-button>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" align="center" width="120">
          <template slot-scope="{row}">
            <el-tag v-if="row.status== -1">未提交</el-tag>
            <el-tag type="info" v-if="row.status==1">待审核</el-tag>
            <el-tag type="success" v-if="row.status==2">已通过</el-tag>
            <el-tag type="danger" v-if="row.status==3">未通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="不通过原因" prop="notPassReason" align="center" width="120"></el-table-column>
        <el-table-column label="运单号" prop="trackingNumber" align="center" width="140"></el-table-column>
        <el-table-column label="计费方式" prop="billingMethod" align="center" width="120">
          <template slot-scope="{row}">
            <el-tag type="info" v-if="row.billingMethod==1">重量</el-tag>
            <el-tag type="info" v-if="row.billingMethod==2">体积</el-tag>
            <el-tag type="info" v-if="row.billingMethod==3">发货件数</el-tag>
            <el-tag type="info" v-if="row.billingMethod==4">重抛</el-tag>
            <el-tag type="info" v-if="row.billingMethod==5">整车</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发货件数" prop="num" align="center" width="140"></el-table-column>
        <el-table-column label="重量（kg）" prop="weight" align="center" width="120"></el-table-column>
        <el-table-column label="体积（m³）" prop="volume" align="center" width="120"></el-table-column>
        <el-table-column label="运费（元）" prop="freight" align="center" width="120"></el-table-column>
        <el-table-column label="备注" prop="remark" align="center" width="120"></el-table-column>
        <el-table-column label="备注2" prop="remark2" align="center" width="120"></el-table-column>
        <el-table-column label="收货人" prop="receiver" align="center"></el-table-column>
        <el-table-column label="收货人电话" prop="receiverPhone" align="center" width="140"></el-table-column>
        <el-table-column label="发出时间" prop="deliveryDate" align="center" width="110"></el-table-column>
        <el-table-column label="收货地" prop="address" align="center" width="150"></el-table-column>
        <el-table-column label="物流公司" prop="logisticsCompanyName" align="center" width="120">
        </el-table-column>
        <el-table-column prop="isFree" label="免运费" width="120" align="center">
          <template slot-scope="{row}">
            <el-tag v-if="row.isFree==1">是</el-tag>
            <el-tag type="danger" v-if="row.isFree==2">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发货员" prop="consignorName" align="center" width="120"></el-table-column>
        <el-table-column label="送货上门" prop="isVisit" align="center" width="140">
          <template slot-scope="{row}">
            <el-tag v-if="row.isVisit==1">是</el-tag>
            <el-tag type="danger" v-if="row.isVisit==2">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="物流单照片" prop="imgUrl" align="center" width="120">
          <template slot-scope="{row}">
            <el-image style="width:23px; height:23px" :src="row.imgUrl"
                      :preview-src-list="row.imgList.map(e => e.url)">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center" class-name="table-operate">
          <template slot-scope="{row}">
            <el-button type="warning" size="mini" round v-if="common.followUp()" @click="revoke(row)">撤回
            </el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
                   :page-size="query.pagesize" @current-change="getList"></el-pagination>
    <el-dialog title="提货通知单明细" :visible.sync="dialogTableVisible">
      <div class="picking-header">
        <div class="picking-title">提货通知单号：{{ pickingData.orderNum }}</div>
        <div class="picking-title">总价：{{ pickingData.totalAmount }}元</div>
      </div>
      <el-table :data="pickingData.invoiceDetails" class="main-table" border header-row-class-name="table-header"
                highlight-current-row height="500">
        <el-table-column property="sparePartCode" label="备件代码"></el-table-column>
        <el-table-column property="sparePartName" label="备件名称"></el-table-column>
        <el-table-column property="num" label="数量"></el-table-column>
        <el-table-column property="costPrice" label="单价"></el-table-column>
        <el-table-column property="amount" label="金额"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import {
  followUpList
} from "@/api/logisticsInformation"
import {deleteLogisticsChecked} from "@/api/statistic";

export default {
  data() {
    return {
      loading: false,
      resetShow: false,
      screenTime: '',
      pickingData: "",
      dialogTableVisible: false,
      tableData: [],
      query: {
        page: 1,
        pagesize: 10,
        sort: '+id'
      },
      total: 0
    }
  },
  mounted() {
    this.getList()
    this.resetShow = true
  },
  methods: {
    refreshData() {
      this.getList()
    },
    revoke(row){
      deleteLogisticsChecked({
        trackingNumber: row.trackingNumber,
        logisticsCompanyId: row.logisticsCompanyId,
        addressId: row.addressId
      }).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '处理成功!'
          });
          this.getList()
          this.$emit('revokeFollowUpClick')
        }
      })
    },
    pickingDetails(row) {
      this.dialogTableVisible = true
      this.pickingData = row
    },
    getList() {
      this.loading = true
      followUpList(this.query).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
  }
}
</script>
<style lang="scss">
.el-table-filter {
  .el-table-filter__bottom {
    button {
      width: 100%;
      border: 1px solid #C0C4CC;
      border-radius: 3px;

      &:last-child {
        display: none !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
// 提货明细
.picking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .picking-title {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
}
</style>
