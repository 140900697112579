<template>
  <div class="statistics-card">
    <div class="statistics-card-title">
      <span style="float: left; font-weight: bolder">大额订单存疑表</span>
      <el-button size="mini" @click="exportExcel" type="primary">导出</el-button>
    </div>
    <el-table :data="tableData" class="statistics-table" height="320" @sort-change='sortChange' :loading="loading">
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          <div class="title-index">
            <i class="icon"
               :class="{'orange':scope.$index%5==1,'yellow':scope.$index%5==2,'green':scope.$index%5==3,'red':scope.$index%5==4}">{{
                scope.$index + 1
              }}</i>
            <span>{{ scope.row.sparePartsTypeName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="regionName" label="地区" align="center" width="45"></el-table-column>
      <el-table-column prop="orderNum" label="提货单编号" width="130" align="center">
        <template slot-scope="scope">
          <span class="click" @click="codeClick(scope.row.id)">{{ scope.row.orderNum }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" sortable="custom" label="货物金额" align="center"
                       width="100">
        <template slot-scope="scope">
          <span class="red"> {{ scope.row.totalAmount }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="totalFreight" label="运费金额" align="center" width="65"></el-table-column>-->
      <!--      <el-table-column prop="proportion" label="运费占比" align="center" width="80">-->
      <!--        <template slot-scope="scope">-->
      <!--          {{ scope.row.proportion }}%-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="" label="操作" align="center" width="50">
        <template slot-scope="scope">
          <el-button class="follow-btn" v-if="common.followUp()" type="danger" size="mini" plain round
                     @click="followUpClick(scope.row)">回访
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <orderDetails ref="orderDetails" :invoiceId="activeId" :type="type"></orderDetails>
  </div>
</template>

<script>
import orderDetails from "./orderDetails.vue"
import {insertLogisticsCheckedByInvoiceId, logisticsDoubtByOrderNumber} from "@/api/statistic";
import {getDate} from "@/common/common";

export default {
  components: {
    orderDetails
  },
  props: ['month', 'type'],
  watch: {
    type() {
      this.getData()
    },
    month() {
      this.query.month = this.month
      this.getData()
    }
  },
  data() {
    return {
      sort: "desc",
      tableData: [],
      loading: false,
      query: {
        month: getDate()
      },
      activeId: null,
      downloadLoading: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    refreshData() {
      this.getData()
    },
    exportExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出Excel表格，请耐心等待！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.downloadLoading = true
      let tHeader = [
        "备品类型",
        "地区",
        "提货单编号",
        "提货金额",
        "录入日期"
      ]
      import('@/vendor/Export2Excel').then(excel => {
        let list = this.tableData
        const data = this.formatJson([
          "sparePartsTypeName",
          "regionName",
          "orderNum",
          "totalAmount",
          "entryDate"], list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '货物金额存疑订单',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
        loading.close();
        this.dialogExportVisible = false
      })

    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {

        if (j === "proportion") {
          return v[j] + '%'
        }
        return v[j]
      }))
    },
    codeClick(id) {
      this.activeId = id
      this.$refs.orderDetails.show()
    },
    getData() {
      this.loading = true
      logisticsDoubtByOrderNumber({type: this.type, sort: this.sort, month: this.query.month}).then(res => {
        if (res.code === 0) {
          this.tableData = res.data
          this.loading = false
        } else {
          this.$message.error(res.desc)
          this.loading = false
        }
      })
    },
    sortChange(column) {//prop标签 => nickname
      if ('ascending' === column.order) {
        this.sort = "asc"
      } else {
        this.sort = "desc"
      }
      this.getData()

    },
    followUpClick(record) {
      this.$confirm('确定要回访吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        insertLogisticsCheckedByInvoiceId(record.id).then(res => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '处理成功!'
            });
            this.getData()
            this.$emit('followUpClick')
          }
        })
      }).catch(() => {
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/statistics.scss";

.red {
  color: red;

}

.click {
  cursor: pointer;
  color: $main-color;
  text-decoration: underline;
}
</style>
